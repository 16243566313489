import { onlyLoaded, useSelector } from '@onefish/flag';
import { useEffect } from 'react';

export const useFlagData = (flagId: number) => {
  const { flag, flagrIds } = useSelector(({ flag: flag2, flagrIds: flagrIds2 }) => {
    return {
      flag: flag2,
      flagrIds: flagrIds2,
    };
  });
  useEffect(() => {
    flag(flagId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const currentStateFlag = onlyLoaded(flagrIds).find((f) => {
    return f.id === flagId;
  });

  return currentStateFlag?.data;
};
